/* tslint:disable:ordered-imports */
/*built-ins*/
import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfig } from './app.config';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

/*modules */
import { TextMaskModule } from 'angular2-text-mask/dist/angular2TextMask';
import { IonRangeSliderModule } from 'ng2-ion-range-slider/index';
import { CustomFormsModule } from 'ng2-validation';
import { BsModalService, ModalBackdropComponent } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { CheckboxModule } from './components/Controls/Checkbox/checkbox.module';
import { ConsumerContactDetailsModalModule } from './components/Controls/ConsumerContactDetailsModal/consumercontactdetailsmodal.module';
import { IconModule } from './components/Controls/Icon/icon.module';
import { MSBButtonModule } from './components/Controls/MSBButton/msbbutton.module';
import { CurrencyMaskModule } from './modules/ng2-currency-mask/index';
import { Ng2DropdownModule } from './modules/ng2-dropdown/ng2-dropdown.module';
import { PasswordStrengthBarModule } from './modules/ng2-password-strength-bar/src/passwordStrengthBar.module';
import { IndexedDbModule } from './services/indexedDb/indexedDb.module';

/*pages*/
import { AccountPaymentComponent } from './pages/accountpayment/accountpayment.component';
import { AccountSelectionComponent } from './pages/accountselection/accountselection.component';
import { AutopayComponent } from './pages/autopay/autopay.component';
import { AutopayEnrollmentConfirmationComponent } from './pages/autopayenrollmentconfirmation/autopayenrollmentconfirmation.component';
import { AutopayEnrollmentComponent } from './pages/autopayenrollment/autopayenrollment.component';
import { BalanceDetailsComponent } from './pages/balancedetails/balancedetails.component';
import { ClearStorageComponent } from './pages/clearstorage/clearstorage.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { DefaultComponent } from './pages/default/default.component';
import { EmailActivationComponent } from './pages/emailactivation/emailactivation.component';
import { EnrollmentComponent } from './pages/enrollment/enrollment.component';
import { ErrorPageComponent } from './pages/error/error.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FormsComponent } from './pages/forms/forms.component';
import { LatestActivityComponent } from './pages/latestactivity/latestactivity.component';
import { LoggedInComponent } from './pages/loggedin/loggedin.component';
import { MEMPaymentComponent } from './pages/mempayment/mempayment.component';
import { ModifiedAccountPaymentAmountComponent } from './pages/modifiedaccountpayment/amount/modifiedaccountpaymentamount.component';
import { ModifiedAccountPaymentMethodComponent } from './pages/modifiedaccountpayment/method/modifiedaccountpaymentmethod.component';
import { ModifiedAccountPaymentReviewComponent } from './pages/modifiedaccountpayment/review/modifiedaccountpaymentreview.component';
import { ModifiedEnrollmentComponent } from './pages/modifiedenrollment/modifiedenrollment.component';
import { MySecureWalletComponent } from './pages/wallet/mysecurewallet.component';
import { ProfileComponent as NewProfileComponent } from './pages/newprofile/profile.component';
import { OutageComponent } from './pages/outage/outage.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentPlanComponent } from './pages/paymentplan/paymentplan.component';
import { PaymentPlanDetailsMessageComponent } from './pages/paymentplanlist/paymentplandetailsmessage/paymentplandetailsmessage.component';
import { PaymentPlanListComponent } from './pages/paymentplanlist/paymentplanlist.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { QuickPayDeliveryProfileComponent } from './pages/quickpaydeliveryprofile/quickpaydeliveryprofile.component';
import { RecurringComponent } from './pages/recurring/recurring.component';
import { RecurringConfirmationComponent } from './pages/recurringconfirmation/recurringconfirmation.component';
import { RecurringActivationComponent } from './pages/recurringactivation/recurringactivation.component';
import { RecurringPaymentComponent } from './pages/recurringpayment/recurringpayment.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { ShowDocumentComponent } from './pages/showdocument/showdocument.component';
import { ShowPaymentComponent } from './pages/showpayment/showpayment.component';
import { SmsEnrollmentComponent } from './pages/sms/smsenrollment.component';
import { StatementHistoryComponent } from './pages/statementhistory/statementhistory.component';
import { UnsubscribeEmailComponent } from './pages/unsubscribeemail/unsubscribeemail.component';

/*components*/
import { AppComponent } from './app.component';
import { ActivityListComponent } from './components/ActivityList/activitylist.component';
import { AutopayAgreementComponent } from './components/AutopayAgreementList/AutopayAgreement/autopayagreement.component';
import { AutopayPendingPaymentComponent } from './components/AutopayAgreementList/AutopayAgreement/AutopayPendingPayment/autopaypendingpayment.component';
import { AutopayAgreementListComponent } from './components/AutopayAgreementList/autopayagreementlist.component';
import { AccountPaymentEntryComponent } from './components/Controls/AccountPaymentEntry/accountpaymententry.component';
import { AccountProfileComponent } from './components/Controls/AccountProfile/accountprofile.component';
import { ActiveUserComponent } from './components/Controls/ActiveUser/activeuser.component';
import { ActivityComponent } from './components/Controls/Activity/activity.component';
import { ConnectMessageComponent } from './components/Controls/Ad/connectmessage.component';
import { ArrowComponent } from './components/Controls/Arrow/arrow.component';
import { BreadcrumbComponent } from './components/Controls/Breadcrumb/breadcrumb.component';
import { ChatLinkComponent } from './components/Controls/ChatLink/chatlink.component';
import { CommunicationPreferenceRowComponent } from './components/Controls/CommunicationPreferences/CommunicationPreferenceRow/communicationPreferenceRow.component';
import { CommunicationPreferencesComponent } from './components/Controls/CommunicationPreferences/communicationpreferences.component';
import { CommunicationPreferenceSelectionComponent } from './components/Controls/CommunicationPreferenceSelection/communicationpreferenceselection.component';
import { ConfirmationModalComponent } from './components/Controls/ConfirmationModal/confirmationmodal.component';
import { ConsumerSystemMessageComponent } from './components/Controls/ConsumerSystemMessage/consumersystemmessage.component';
import { CustomWidgetComponent } from './components/Controls/CustomWidget/customwidget.component';
import { EnrollmentProfileComponent } from './components/Controls/EnrollmentProfile/enrollmentprofile.component';
import { ErrorMessageComponent } from './components/Controls/ErrorMessage/errormessage.component';
import { FeedbackComponent } from './components/Controls/Feedback/feedback.component';
import { FooterComponent } from './components/Controls/Footer/footer.component';
import { HelpComponent } from './components/Controls/Help/help.component';
import { HomeButtonComponent } from './components/Controls/HomeButton/homebutton.component';
import { LoggedInHeaderComponent } from './components/Controls/LoggedInHeader/loggedinheader.component';
import { LogoComponent } from './components/Controls/Logo/logo.component';
import { ManageDeliveryProfileComponent } from './components/Controls/ManageDeliveryProfile/managedeliveryprofile.component';
import { ManageSmsProfileComponent } from './components/Controls/managesmsprofile/managesmsprofile.component';
import { MessageWithLinkComponent } from './components/Controls/MessageWithLink/messagewithlink.component';
import { ModifiedAccountPaymentEntryComponent } from './components/Controls/ModifiedAccountPaymentEntry/modifiedaccountpaymententry.component';
import { MSBCarouselComponent } from './components/Controls/MSBCarousel/msbcarousel.component';
import { MSBLinkComponent } from './components/Controls/MSBLink/msblink.component';
import { MSBLinkADAComponent } from './components/Controls/MSBLinkADA/msblinkada.component';
import { MSBWrapperComponent } from './components/Controls/MSBWrapper/msbwrapper.component';
import { AccountProfileComponent as NewAccountProfileComponent } from './components/Controls/newprofile/accountprofile.component';
import { NewQuickPayManageDeliveryFormComponent } from './components/Controls/NewQuickPayManageDeliveryForm/new-quick-pay-manage-delivery-form.component';
import { PasswordResetComponent } from './components/Controls/PasswordReset/passwordreset.component';
import { PaymentBucketDisplayComponent } from './components/Controls/PaymentBucketDisplay/paymentbucketdisplay.component';
import { PaymentMethodDropdownComponent } from './components/Controls/PaymentMethodDropdown/paymentmethoddropdown.component';
import { PaymentMethodLogoComponent } from './components/Controls/PaymentMethodLogo/paymentmethodlogo.component';
import { PaymentMethodManagerComponent } from './components/Controls/PaymentMethodManager/paymentmethodmanager.component';
import { PaymentMethodModalComponent } from './components/Controls/PaymentMethodModal/paymentmethodmodal.component';
import { PaymentMethodDisplayComponent } from './components/Controls/PaymentSelection/PaymentMethodDisplay/paymentmethoddisplay.component';
import { PaymentSelectionComponent } from './components/Controls/PaymentSelection/paymentselection.component';
import { PoweredbyComponent } from './components/Controls/PoweredBy/poweredby.component';
import { ProfileCompleteComponent } from './components/Controls/ProfileComplete/profilecomplete.component';
import { ProgressBarComponent } from './components/Controls/ProgressBar/progressbar.component';
import { SmallPaymentMethodDisplayComponent } from './components/Controls/SmallPaymentMethodDisplay/smallpaymentmethoddisplay.component';
import { SystemMessageComponent } from './components/Controls/SystemMessage/systemmessage.component';
import { AutopayWidgetComponent } from './components/Dashboard/AutoPay/autopaywidget.component';
import { DeliveryMethodWidgetComponent } from './components/Dashboard/DeliveryMethod/deliverymethodwidget.component';
import { PaymentPlansWidgetComponent } from './components/Dashboard/PaymentPlans/paymentplanswidget.component';
import { ProfileStatusComponent } from './components/Dashboard/Profile/ProfileStatus/profilestatus.component';
import { ProfileWidgetComponent } from './components/Dashboard/Profile/profilewidget.component';
import { RecurringWidgetComponent } from './components/Dashboard/RecurringPlans/recurringwidget.component';
import { StatementBalanceComponent } from './components/Dashboard/Statement/StatementBalance/statementbalance.component';
import { StatementLastPaymentComponent } from './components/Dashboard/Statement/StatementLastPayment/statementlastpayment.component';
import { StatementWidgetComponent } from './components/Dashboard/Statement/statementwidget.component';
import { StatementHistoryWidgetComponent } from './components/Dashboard/StatementHistory/statementhistorywidget.component';
import { LandingItemComponent } from './components/LandingList/LandingItem/landingitem.component';
import { LandingListComponent } from './components/LandingList/landinglist.component';
import { LandingListLoginComponent } from './components/LandingList/landinglistlogin.component';
import { LatestActivityByYearComponent } from './components/LatestActivityByYear/latestactivitybyyear.component';
import { PayableEntryComponent } from './components/PayableItemList/PayableEntry/payableentry.component';
import { PayableItemComponent } from './components/PayableItemList/PayableItem/payableitem.component';
import { PayableItemListComponent } from './components/PayableItemList/payableitemlist.component';
import { PayableStatementComponent } from './components/PayableItemList/PayableStatement/payablestatement.component';
import { PaymentBucketComponent } from './components/PayableItemList/PayableStatement/PaymentBucket/paymentbucket.component';
import { PaymentPlanDetailComponent } from './components/PaymentPlan/paymentplandetail/paymentplandetail.component';
import { RecaptchaComponent } from './components/RecaptchaComponent/recaptcha.component';
import { RecurringPlanComponent } from './components/RecurringPlanList/RecurringPlan/recurringplan.component';
import { RecurringPlanListComponent } from './components/RecurringPlanList/recurringplanlist.component';
import { StatementHistoryPaymentComponent } from './components/StatementHistory/Payment/statementhistorypayment.component';
import { StatementHistoryStatementComponent } from './components/StatementHistory/Statement/statementhistorystatement.component';
import { StatementHistoryStatementsByYearComponent } from './components/StatementHistory/StatementsByYear/statementsbyyear.component';
import { NewQuickPayManageDeliveryProfileComponent } from './pages/newquickpaydeliveryprofile/new-quick-pay-manage-delivery-profile.component';
import { QuickPaySmsProfileComponent } from './pages/quickpaysmsprofile/quickpaysmsprofile.component';
import { EpicSsoComponent } from './pages/epic-sso/epic-sso.component';
import { SelectAccountsModalComponent } from './components/Controls/SelectAccountsModal/selectaccountsmodal.component';
import { SelectAccountsComponent } from './components/Controls/SelectAccountsModal/SelectAccounts/selectaccounts.component';
import { AccountDetailsModalComponent } from './components/Controls/AccountDetailsModal/accountdetailsmodal.component';

/*services*/

import { AgentAssistedService } from './services/agentassisted/agentassisted.service';
import { ArchiveService } from './services/archive/archive.service';
import { ComponentService } from './services/component/component.service';
import { ConnectMessageService } from './services/connectmessage/connectmessage.service';
import { ConsumerService } from './services/consumer/consumer.service';
import { DomainContentItemsService } from './services/contentItems/domaincontentitems.service';
import { DomainInfoService } from './services/domainInfo/domaininfo.service';
import { EpicSsoService } from './services/epic-sso/epic-sso.service';
import { AccountPaymentGuard } from './services/guard/account-payment.guard';
import { EnrollmentGuard } from './services/guard/enrollment.guard.service';
import { FutureDateAccountPaymentService } from './services/futuredateaccountpayment/futuredateaccountpayment.service';
import { HomeGuard } from './services/guard/home.guard.service';
import { LoginGuard } from './services/guard/login.guard.service';
import { MemCodeLoginGuard } from './services/guard/mem-code-login.guard';
import { ProfileGuard } from './services/guard/profile.guard';
import { QuickpayDeliveryProfileGuard } from './services/guard/quickpay-delivery-profile.guard';
import { RoleGuard } from './services/guard/role.guard.service';
import { SmsGuard } from './services/guard/sms.guard';
import { WalletGuard } from './services/guard/wallet.guard';
import { LoggingService } from './services/logging/logging.service';
import { LoginService } from './services/login/login.service';
import { ModifiedAccountPaymentStateService } from './services/modifiedaccountpayment/modifiedaccountpayment.state.service';
import { PayableService } from './services/payable/payable.service';
import { PaymentService } from './services/payment/payment.service';
import { PaymentPlanService } from './services/paymentplan/paymentplan.service';
import { PaymentSelectionStateService } from './services/paymentselection/paymentselection.state.service';
import { ConsumerStorageService } from './services/indexedDb/consumer-storage.service';
import { StorageService } from './services/storage/storage.service';
import { ValidationService } from './services/validation/validation.service';
import { AccountSelectionService } from './pages/accountselection/accountselection.service';
import { GlobalErrorHandler } from './util/globalErrorHandler';

/*pipes*/
import { DatePipe } from '@angular/common';
import { ModalContainerComponent } from 'ngx-bootstrap/modal';
import { CoverUpPipe } from './pipes/CoverUp/coverup.pipe';
import { CurrencyDisplayPipe } from './pipes/CurrencyDisplay/currencydisplay.pipe';
import { DateFormatPipe } from './pipes/DateFormat/dateformat.pipe';
import { PhonePipe } from './pipes/Phone/phone.pipe';
import { ValidDatePipe } from './pipes/ValidDate/validdate.pipe';

/*directives*/
import { AutofocusDirective } from './directives/autofocus.directive';
import { DisableAfterClickDirective } from './directives/disable-after-click.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { HttpXsrfInterceptor } from './util/HttpClientUtility';
import { FutureDateAccountPaymentConfirmationComponent } from './pages/futuredateaccountpaymentconfirmation/futuredateaccountpaymentconfirmation.component';
import { EStatementPromptModalComponent } from './components/Controls/EStatementPromptModal/estatement-prompt-modal.component';

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        CustomFormsModule,
        CurrencyMaskModule,
        Ng2DropdownModule,
        CarouselModule,
        BsDropdownModule,
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        NgxWebstorageModule.forRoot(),
        TextMaskModule,
        PasswordStrengthBarModule,
        IonRangeSliderModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions(),
        ConsumerContactDetailsModalModule,
        CheckboxModule,
        IconModule,
        MSBButtonModule,
        IndexedDbModule,
        MatMenuModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatSlideToggleModule
    ],

    declarations: [
        /* pages */
        DefaultComponent,
        LoggedInComponent,
        PaymentComponent,
        MEMPaymentComponent,
        RedirectComponent,
        EnrollmentComponent,
        AccountPaymentComponent,
        AccountSelectionComponent,
        ConfirmationComponent,
        FutureDateAccountPaymentConfirmationComponent,
        ShowDocumentComponent,
        ShowPaymentComponent,
        ClearStorageComponent,
        FaqComponent,
        FormsComponent,
        ProfileComponent,
        NewProfileComponent,
        StatementHistoryComponent,
        MySecureWalletComponent,
        LatestActivityComponent,
        AutopayComponent,
        AutopayEnrollmentConfirmationComponent,
        AutopayEnrollmentComponent,
        PaymentPlanComponent,
        PaymentPlanListComponent,
        BalanceDetailsComponent,
        SmsEnrollmentComponent,
        RecaptchaComponent,
        RecurringComponent,
        RecurringConfirmationComponent,
        RecurringActivationComponent,
        RecurringPaymentComponent,
        OutageComponent,
        ErrorPageComponent,
        ModifiedAccountPaymentAmountComponent,
        ModifiedAccountPaymentMethodComponent,
        ModifiedAccountPaymentReviewComponent,
        ModifiedEnrollmentComponent,
        EmailActivationComponent,
        QuickPayDeliveryProfileComponent,
        UnsubscribeEmailComponent,

        /* components */
        AppComponent,
        ProfileWidgetComponent,
        CustomWidgetComponent,
        ProfileCompleteComponent,
        ProfileStatusComponent,
        AutopayWidgetComponent,
        PaymentPlansWidgetComponent,
        StatementHistoryWidgetComponent,
        DeliveryMethodWidgetComponent,
        StatementWidgetComponent,
        StatementBalanceComponent,
        StatementLastPaymentComponent,
        ActivityListComponent,
        MSBWrapperComponent,
        ConnectMessageComponent,
        ActiveUserComponent,
        ActivityComponent,
        LatestActivityByYearComponent,
        BreadcrumbComponent,
        MSBCarouselComponent,
        FeedbackComponent,
        HelpComponent,
        LogoComponent,
        PaymentMethodLogoComponent,
        PaymentSelectionComponent,
        MSBLinkComponent,
        MSBLinkADAComponent,
        PoweredbyComponent,
        LandingListComponent,
        LandingListLoginComponent,
        LandingItemComponent,
        CurrencyDisplayPipe,
        FooterComponent,
        LoggedInHeaderComponent,
        HomeButtonComponent,
        PayableItemListComponent,
        PayableStatementComponent,
        PayableItemComponent,
        PayableEntryComponent,
        PaymentBucketComponent,
        PaymentBucketDisplayComponent,
        PaymentMethodDisplayComponent,
        SmallPaymentMethodDisplayComponent,
        ValidDatePipe,
        PhonePipe,
        DateFormatPipe,
        CoverUpPipe,
        ProgressBarComponent,
        ArrowComponent,
        AccountPaymentEntryComponent,
        ModifiedAccountPaymentEntryComponent,
        PasswordResetComponent,
        AccountProfileComponent,
        NewAccountProfileComponent,
        CommunicationPreferencesComponent,
        CommunicationPreferenceSelectionComponent,
        ManageSmsProfileComponent,
        MessageWithLinkComponent,
        EnrollmentProfileComponent,
        ConsumerSystemMessageComponent,
        SystemMessageComponent,
        StatementHistoryStatementComponent,
        StatementHistoryPaymentComponent,
        StatementHistoryStatementsByYearComponent,
        ConfirmationModalComponent,
        PaymentMethodModalComponent,
        ModalBackdropComponent,
        ModalContainerComponent,
        AutopayAgreementComponent,
        AutopayAgreementListComponent,
        EqualValidator,
        AutofocusDirective,
        AutopayPendingPaymentComponent,
        DisableAfterClickDirective,
        PaymentPlanDetailComponent,
        PaymentMethodDropdownComponent,
        PaymentMethodManagerComponent,
        RecaptchaComponent,
        RecurringPlanListComponent,
        RecurringPlanComponent,
        PaymentPlanDetailsMessageComponent,
        RecurringWidgetComponent,
        ErrorMessageComponent,
        ManageDeliveryProfileComponent,
        QuickPaySmsProfileComponent,
        ChatLinkComponent,
        CommunicationPreferenceRowComponent,
        NewQuickPayManageDeliveryProfileComponent,
        NewQuickPayManageDeliveryFormComponent,
        EpicSsoComponent,
        SelectAccountsModalComponent,
        SelectAccountsComponent,
        EStatementPromptModalComponent,
        AccountDetailsModalComponent
    ],
    providers: [
        LoggingService,
        ComponentService,
        DomainInfoService,
        DomainContentItemsService,
        ConsumerService,
        LoginService,
        CurrencyPipe,
        DateFormatPipe,
        DatePipe,
        AppConfig,
        LoginGuard,
        RoleGuard,
        EnrollmentGuard,
        HomeGuard,
        ProfileGuard,
        AccountPaymentGuard,
        AgentAssistedService,
        StorageService,
        PaymentService,
        FutureDateAccountPaymentService,
        ArchiveService,
        ValidationService,
        BsModalService,
        PaymentPlanService,
        ModifiedAccountPaymentStateService,
        PaymentSelectionStateService,
        PayableService,
        ConnectMessageService,
        MemCodeLoginGuard,
        QuickpayDeliveryProfileGuard,
        WalletGuard,
        SmsGuard,
        ConsumerStorageService,
        EpicSsoService,
        AccountSelectionService,
        PhonePipe,
        { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    entryComponents: [
        ModalBackdropComponent,
        ModalContainerComponent,
        PayableEntryComponent,
        PaymentMethodModalComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
