import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import { AppConfig } from '../../app.config';
import { IAgentAssistedConsumerPayment } from '../../models/agentassistedconsumerpayment';
import { IAgentAssistedValidation } from '../../models/agentAssistedValidation';
import { IAutopayAgreement } from '../../models/autopayagreement';
import { GenericResponse, Response } from '../../models/genericresponse';
import { IRecurringPaymentPlan } from '../../models/recurringpaymentplan';
import { IStagedPaymentPlanResponse } from '../../models/stagedpaymentplanresponse';
import { jsonRequestOptions } from '../../util/HttpClientUtility';
import { ComponentService } from '../component/component.service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class AgentAssistedService {
    private domain = '';
    private agentAssistedValidateAAPaymentExpirationPath: string;
    private agentAssistedgetAAEmailTextValidationMethodPath: string;
    private getStagedPaymentPlanPath: string;
    private agentAssistedGetAAPaymentAmountPath: string;
    private getAutopayAgreementByAgentAssistedPaymentPath: string;
    private getPaymentPlanByAgentAssistedPaymentPath: string;
    private getRecurringPaymentPlanByAgentAssistedPaymentPath: string;
    private agentAssistedGetStagedAutopayAgreementPath: string;
    private agentAssistedGetRetrieveRecurringPaymentPath: string;
    private domainUrl = this.config.getConfig('domainInfo');
    private agentAssistedUpdatePaymentWithWalletPath: string;

    submitPaymentError: string;

    constructor(
        private http: HttpClient,
        private config: AppConfig,
        private componentService: ComponentService,
        private loggingService: LoggingService,
    ) {
        this.domain = this.componentService.storageService.getDomain();
        this.agentAssistedValidateAAPaymentExpirationPath = config.getConfig('agentAssistedValidateAAPaymentExpirationPath');
        this.agentAssistedgetAAEmailTextValidationMethodPath = config.getConfig('agentAssistedgetAAEmailTextValidationMethodPath');
        this.getStagedPaymentPlanPath = config.getConfig('getStagedPaymentPlanPath');
        this.agentAssistedGetAAPaymentAmountPath = config.getConfig('agentAssistedGetAAPaymentAmountPath');
        this.getAutopayAgreementByAgentAssistedPaymentPath = config.getConfig('getAutopayAgreementByAgentAssistedPaymentPath');
        this.getPaymentPlanByAgentAssistedPaymentPath = config.getConfig('getPaymentPlanByAgentAssistedPaymentPath');
        this.getRecurringPaymentPlanByAgentAssistedPaymentPath = config.getConfig('getRecurringPaymentPlanByAgentAssistedPaymentPath');
        this.agentAssistedGetStagedAutopayAgreementPath = config.getConfig('agentAssistedGetStagedAutopayAgreementPath');
        this.agentAssistedGetRetrieveRecurringPaymentPath = config.getConfig('agentAssistedGetRetrieveRecurringPaymentPath');
        this.agentAssistedUpdatePaymentWithWalletPath = config.getConfig('agentAssistedUpdatePaymentWithWalletPath');
    }

    /**
     * Used to validate that the AgentAssisted GUID was found and not expired.
     *
     * @param agentAssistedPaymentGuid
     * @returns {Promise<boolean>}
     */
    public async isAgentAssistedGUIDValid(agentAssistedGUID: string): Promise<IAgentAssistedValidation> {
        return this.callIsAgentAssistedGUIDValid(agentAssistedGUID);
    }

    /**
     * Used to retrieve the AgentAssistedgetAAEmailTextValidationMethod.
     *
     * @param agentAssistedPaymentGuid
     * @returns {Promise<string>}
     */
    public async getAgentAssistedEmailTextValidationMethod(agentAssistedGUID: string): Promise<string> {
        return this.callGetAgentAssistedEmailTextValidationMethod(agentAssistedGUID);
    }

    /**
     * Retrieve a IPaymentPlanDetails object with the values for the payment plan
     * associated with the given agentAssistedGUID
     */
    public async getStagedPaymentPlan(agentAssistedGUID: string): Promise<IStagedPaymentPlanResponse> {
        return this.callGetStagedPaymentPlanApi(agentAssistedGUID);
    }

    public async getAutopayAgreementByAgentAssistedPayment(agentAssistedGUID: string): Promise<IAutopayAgreement> {
        return this.callGetAutopayAgreementByAgentAssistedPayment(agentAssistedGUID);
    }

    public async getPaymentPlanByAgentAssistedPayment(agentAssistedGUID: string): Promise<string> {
        return this.callGetPaymentPlanByAgentAssistedPayment(agentAssistedGUID);
    }

    public async getRecurringPaymentPlanByAgentAssistedPayment(agentAssistedGUID: string): Promise<IRecurringPaymentPlan> {
        return this.callGetRecurringPaymentPlanByAgentAssistedPayment(agentAssistedGUID);
    }

    public async retrieveAgentAssistedAmountToPay(token: string, agentAssistedGUID: string): Promise<IAgentAssistedConsumerPayment> {
        if (this.componentService.storageService.exists('agentAssistedConsumerPayment')) {
            return this.componentService.storageService.retrieve('agentAssistedConsumerPayment');
        } else {
            const agentAssistedConsumerPayment = this.callRetrieveAgentAssistedAmountToPay(token, agentAssistedGUID);
            this.componentService.storageService.store('agentAssistedConsumerPayment', agentAssistedConsumerPayment);

            return agentAssistedConsumerPayment;
        }
    }

    public async retrieveAutopayAgreement(token: string, agentAssistedGUID: string): Promise<IAutopayAgreement> {
        return this.callRetrieveAutopayAgreement(token, agentAssistedGUID);
    }

    public async retrieveRecurringmentPayment(token: string, agentAssistedGUID: string): Promise<IRecurringPaymentPlan> {
        return this.callRetrieveRecurringmentPayment(token, agentAssistedGUID);
    }

    public async updateAgentAssistedPaymentWithWallet(agentAssistedGUID: string, token: string): Promise<boolean> {
        return this.callUpdateAgentAssistedPaymentWithWallet(agentAssistedGUID, token);
    }

    private async callUpdateAgentAssistedPaymentWithWallet(agentAssistedGUID: string, token: string): Promise<boolean> {
        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent('nouser/' +
                this.agentAssistedUpdatePaymentWithWalletPath +
                '/' + agentAssistedGUID) + '/' + token +
            '&requireCredentials=false';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callRetrieveRecurringmentPayment(token: string, agentAssistedGUID: string): Promise<IRecurringPaymentPlan> {
        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' +
                this.agentAssistedGetRetrieveRecurringPaymentPath +
                '/' + agentAssistedGUID) +
            '&requireCredentials=true';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<number>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callRetrieveAutopayAgreement(token: string, agentAssistedGUID: string): Promise<IAutopayAgreement> {
        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' +
                this.agentAssistedGetStagedAutopayAgreementPath +
                '/' + agentAssistedGUID) +
            '&requireCredentials=true';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<number>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callRetrieveAgentAssistedAmountToPay(token: string, agentAssistedGUID: string): Promise<IAgentAssistedConsumerPayment> {
        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' +
                this.agentAssistedGetAAPaymentAmountPath +
                '/' + agentAssistedGUID) +
            '&requireCredentials=true';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IAgentAssistedConsumerPayment>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callIsAgentAssistedGUIDValid(agentAssistedGUID: string): Promise<IAgentAssistedValidation> {
        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent('nouser/' +
                this.agentAssistedValidateAAPaymentExpirationPath +
                '/' + agentAssistedGUID) +
            '&requireCredentials=false';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetAgentAssistedEmailTextValidationMethod(agentAssistedGUID: string): Promise<string> {
        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent('nouser/' +
                this.agentAssistedgetAAEmailTextValidationMethodPath +
                '/' + agentAssistedGUID) +
            '&requireCredentials=false';
        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) => this.loggingService.handleError(response));
    }

    private async callGetStagedPaymentPlanApi(agentAssistedGUID: string): Promise<IStagedPaymentPlanResponse> {
        const token = this.componentService.storageService.retrieve('token');

        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' + this.getStagedPaymentPlanPath + '/' + agentAssistedGUID) + '&requireCredentials=true';

        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<IStagedPaymentPlanResponse>) => response.body.data)
            .catch((response: GenericResponse) =>
                this.loggingService.handleError(response));
    }

    private async callGetPaymentPlanByAgentAssistedPayment(agentAssistedGUID: string): Promise<string> {
        const token = this.componentService.storageService.retrieve('token');

        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' + this.getPaymentPlanByAgentAssistedPaymentPath + '/' + agentAssistedGUID) + '&requireCredentials=true';

        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) =>
                this.loggingService.handleError(response));
    }

    private async callGetAutopayAgreementByAgentAssistedPayment(agentAssistedGUID: string): Promise<IAutopayAgreement> {
        const token = this.componentService.storageService.retrieve('token');

        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' + this.getAutopayAgreementByAgentAssistedPaymentPath + '/' + agentAssistedGUID) + '&requireCredentials=true';

        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) =>
                this.loggingService.handleError(response));
    }

    private async callGetRecurringPaymentPlanByAgentAssistedPayment(agentAssistedGUID: string): Promise<IRecurringPaymentPlan> {
        const token = this.componentService.storageService.retrieve('token');

        const path: string = this.domainUrl + '?relativePath=' +
            encodeURIComponent(token + '/' + this.getRecurringPaymentPlanByAgentAssistedPaymentPath + '/' + agentAssistedGUID) + '&requireCredentials=true';

        return this.http.get(path, jsonRequestOptions())
            .toPromise()
            .then((response: Response<string>) => response.body.data)
            .catch((response: GenericResponse) =>
                this.loggingService.handleError(response));
    }
}
