import { CurrencyPipe, DatePipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../models/domaininfo';
import { PayableItem, PayableItemType } from '../../models/payableitem';
import { PayableStatement } from '../../models/payablestatement';
import { BalanceMethod, IPaymentPlanBalance } from '../../models/paymentplanbalance';
import { AgentAssistedService } from '../../services/agentassisted/agentassisted.service';
import { ComponentService } from '../../services/component/component.service';
import { ConsumerService } from '../../services/consumer/consumer.service';
import { LoggingLevel } from '../../services/logging/logging.service';
import { PaymentPlanService } from '../../services/paymentplan/paymentplan.service';
import { PayableItemComponent } from './PayableItem/payableitem.component';
import { PayableStatementComponent } from './PayableStatement/payablestatement.component';

import { BalanceDetailsPostingMethod } from '../../models/balancedetailspostingmethod';
import { PayableEntry, PayableEntryType } from '../../models/PayableEntry';
import { IPaymentPlanDetails } from '../../models/paymentplandetails';
import { PayableEntryComponent } from './PayableEntry/payableentry.component';
@Component({
    selector: 'payable-item-list',
    template: require('./payableitemlist.component.html'),
    styles: [require('./payableitemlist.component.css')]
})
export class PayableItemListComponent implements OnInit, OnDestroy {
    // Get PayableStatement and PayableItem component children
    @ViewChildren('payableStatementCmp') payableStatementCmpList: QueryList<PayableStatementComponent>;
    @ViewChildren('payableItemCmp') payableItemCmpList: QueryList<PayableItemComponent>;
    @ViewChildren('payableEntryCmp') payableEntryCmpList: QueryList<PayableEntryComponent>;

    @Input() currentBalanceDisclaimer: string;
    @Input() clearSelectionsText: string;
    @Input() isOneTimePayment: boolean;
    @Input() previousPaymentWarning: string;
    @Input() noPayableItems = false;
    @Input() quickPayBalanceDetailsWarning = '';

    @Output() amountChangedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() internalServerErrorEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() emitOverPaymentEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    statementsLoading = true;
    walletLoading = true;
    paymentPlansLoading = false;
    balancesLoading = false;
    calculatedSum = 0;
    paymentPlanSum = 0;
    realtimeBalance: PayableItem;
    statementList: PayableStatement[] = [];
    payableEntryList: PayableEntry[] = [];
    lastStatement: PayableStatement;
    showPaymentPlanBalances = false;
    paymentPlans: PayableItem[] = [];
    payableEntryPaymentPlans: PayableEntry[] = [];
    paymentPlanBalances: PayableItem[] = [];
    payableEntryPaymentPlanBalances: PayableEntry[] = [];
    payableItems: PayableItem[] = [];
    accountBalanceHeaderText: string;
    paymentPlanHeaderText: string;
    paymentPlanRemainingBalanceText: string;
    noPayableItemsText: string;
    checkedExternalPayments = false;
    noPayableBalancesExist = true;
    noPaymentPlansExist = true;
    domainTotalBalanceType: string;
    lastStatementStepOneOtherHeader: string;
    domainInfo: IDomainInfo;
    isAgentAssisted = false;
    agentAssistedPaymentGuid: string;
    consumerToken: string;
    memCode: string;

    /**
     * If the current consumer has a consumer
     * account. This is a way to communicate
     * with the payment component about
     * the QuickPay Balance Details header information
     */
    hasConsumerAccount = false;

    totalText: string;
    paymentPlanBalanceMessage: string;

    enableQuickPayBalanceDetails = false;

    paymentPlansAndBalancesErrorText: string;
    paymentPlansAndBalancesError = '';

    private ngUnsubscribe: Subject<any> = new Subject();
    comp: PayableItem;

    constructor(
        private agentAssistedService: AgentAssistedService,
        private componentService: ComponentService,
        private consumerService: ConsumerService,
        private paymentPlanService: PaymentPlanService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
    ) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async ngOnInit() {
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                if (this.isOneTimePayment) {
                    this.totalText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'oneTimePayment', 'paymentPaymentTotalLabel').text;
                } else {
                    this.totalText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentPaymentTotalLabel').text;
                }

                this.paymentPlanBalanceMessage = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentPaymentPlanBalanceDisclaimer').text;
                this.accountBalanceHeaderText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentAccountBalanceHeaderText').text;
                this.noPayableItemsText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentNoPayableItemsText').text;
                this.paymentPlanHeaderText = this.componentService.contentService.tryGetContentItem(content, 'payment', 'pageText', 'paymentPaymentPlanHeaderText').text;
                this.paymentPlanRemainingBalanceText = this.componentService.contentService.tryGetContentItem(
                    content, 'payment', 'pageText', 'paymentPaymentPlanRemainingBalanceText').text;
                this.paymentPlansAndBalancesErrorText = this.componentService.contentService.tryGetContentItem(
                    content, 'error', 'oneTimePayment', 'memPaymentFetchingBalanceError').text;
                this.lastStatementStepOneOtherHeader = this.componentService.contentService.tryGetContentItem(content, 'payment', 'loggedInPayment', 'paymentLastStatementStep1OtherHeader').text;
            });

        this.domainInfo = await this.componentService.domainService.getDomainInfo();
        this.domainTotalBalanceType = this.domainInfo.totalBalanceType;
        this.enableQuickPayBalanceDetails = this.domainInfo.enableQuickPayBalanceDetails;
        this.memCode = this.componentService.storageService.retrieve('mem');
        this.isAgentAssisted = this.componentService.storageService.exists('agentAssistedPaymentEmailGUID');

        this.agentAssistedPaymentGuid =
                this.componentService.storageService.retrieve(
                    'agentAssistedPaymentEmailGUID'
                );

        let agentAssistDocumentID = 0;
        if (this.isAgentAssisted) {
            this.consumerToken = this.componentService.storageService.retrieve('token');
            const agentAssistedConsumerPayment =
                await this.agentAssistedService.retrieveAgentAssistedAmountToPay(
                    this.consumerToken,
                    this.agentAssistedPaymentGuid
                );

            if (agentAssistedConsumerPayment) {
                agentAssistDocumentID = agentAssistedConsumerPayment.documentID;
            }
        }

        // Try getting payment plans/balance-details if :
        // 1) you're in a QuickPay experience, and domain settings are enabled
        // 2) you're in a "logged-in" experience
        // 1 and 2 apply regardless of domainInfo.totalBalanceType
        // 3) Was added in APX-19111 because we weren't getting
        // into this logic on Deviceless Future Dated Payments.
        if (
            (this.isOneTimePayment && (this.memCode && this.enableQuickPayBalanceDetails)) ||
            !this.isOneTimePayment ||
            (this.isAgentAssisted && !agentAssistDocumentID)
        ) {
            this.paymentPlansLoading = true;
            this.balancesLoading = true;
            await this.getPaymentPlansAndBalances();
            this.paymentPlansLoading = false;
            this.balancesLoading = false;
        }

        // This prevents the scenario described in APX-19193.
        if (!(this.isAgentAssisted && !agentAssistDocumentID)) {
            await Promise.race([
                this.getStatementsFromConsumerService(),
                setTimeout(() => {
                    this.statementsDoneLoading();
                    this.walletDoneLoading();
                }, 30000),
            ]);
        }
    }

    emitOverPayment(canMakePayment: boolean): void {
        this.emitOverPaymentEvent.emit(canMakePayment);
    }

    sumTotal(): void {
        let payableSum: number = null;
        const statementSum: number = this.payableStatementCmpList.filter((x) => !x.isDisabled).reduce(
            (sum, val) => sum + Math.abs(val.statement.paymentAmount), 0);

        if (this.domainInfo.enablePayableEntries) {
            // If there is an initial payment, pay on that first
            if (!!this.payableEntryPaymentPlans &&
                this.payableEntryPaymentPlans.length > 0 &&
                this.payableEntryPaymentPlans[0].initialPayment &&
                this.payableEntryPaymentPlans[0].paymentAmount !== 0) {
                payableSum = this.payableEntryPaymentPlans[0].initialPaymentAmount;
            } else {
                payableSum = this.payableEntryCmpList.filter((x) => !x.payableEntry.isDisabled).reduce(
                    (sum, val) => sum + Math.abs(val.payableEntry.paymentAmount), 0);
            }
        } else {
            // If there is an initial payment, pay on that first
            if (!!this.payableItems &&
                this.payableItems.length > 0 &&
                this.payableItems[0].initialPayment &&
                this.payableItems[0].paymentAmount !== 0) {
                payableSum = this.payableItems[0].initialPaymentAmount;
            } else {
                payableSum = this.payableItemCmpList.filter((x) => !x.isDisabled).reduce(
                    (sum, val) => sum + Math.abs(val.payableItem.paymentAmount), 0);

                if (!!this.payableItems && this.payableItems.length > 1) {
                    for (const payableItem of this.payableItems) {
                        if (payableItem.paymentAmount === 0 ||
                            (payableItem.paymentAmount > payableItem.maxPayableAmount)) {
                            this.calculatedSum = statementSum + payableSum;
                            this.amountChangedEvent.emit(true);
                            this.emitOverPayment(false);
                            return;
                        }
                        else {
                            this.emitOverPayment(true);
                        }
                    }
                }
            }
        }

        // APX-9017 began introducting PayableEntries, but today they only apply to GroupedAccountBalance payments.
        // For statement payments and other account payments, we want to use the sum from PayableStatements and PayableItems,
        // regardless of the enablePayableEntries flag.
        // TODO: this comment and the ` && (statementSum + payableItemSum === 0)` should be removed when APX-9845 / APX-9846
        //      have completed the refactor to PayableEntries.
        if (this.domainInfo.enablePayableEntries && (statementSum + payableSum === 0)) {
            this.calculatedSum = payableSum;
        } else {
            this.calculatedSum = statementSum + payableSum;
        }

        this.amountChangedEvent.emit(true);
    }

    /**
     * Determines which rows to disable/enable based on the itemType and merchantProfileGUID of the checked Item
     *
     * @param {PayableEntry} clickedItem
     *
     * @memberOf PayableItemListComponent
     */
    payToPayableEntryChecked(clickedItem: PayableEntry): void {
        const otherItemMerchants: PayableEntryComponent[] = this.payableEntryCmpList.filter(
            (x) => x.payableEntry.merchantProfileGUID !== clickedItem.merchantProfileGUID);
        const sameItemMerchants: PayableEntryComponent[] = this.payableEntryCmpList.filter(
            (x) => x.payableEntry.merchantProfileGUID === clickedItem.merchantProfileGUID);

        if (clickedItem.payToItem) {
            // set non-equal merchant guids to disabled
            otherItemMerchants.forEach((y) => {
                y.payableEntry.isDisabled = true;
                y.clearPayableEntryAndChildItems();
            });
            // set all equivalent merchant statements within itemtype to not disabled
            sameItemMerchants.forEach((y) => y.payableEntry.isDisabled = false);
        }
        if (!clickedItem.payToItem) {
            // make sure no other checkboxes in rows of the same merchant are checked, then re-enable "other" rows
            if (!sameItemMerchants.some((y) => y.payableEntry.payToItem)) {
                otherItemMerchants.forEach((y) => {
                    y.payableEntry.isDisabled = false;
                });
            }
        }
        // re-calculate the total
        this.sumTotal();
    }

    /**
     * Determines which rows to disable/enable based on the itemType and merchantProfileGUID of the checked PayableItem
     *
     * @param {PayableItem} clickedItem
     *
     * @memberOf PayableItemListComponent
     */
    payToItemChecked(clickedItem: PayableItem): void {
        const otherItemMerchants: PayableItemComponent[] = this.payableItemCmpList.filter(
            (x) => x.payableItem.merchantProfileGUID !== clickedItem.merchantProfileGUID);

        const sameItemMerchants: PayableItemComponent[] = this.payableItemCmpList.filter(
            (x) => x.payableItem.merchantProfileGUID === clickedItem.merchantProfileGUID);

        if (clickedItem.payToItem) {
            // set non-equal merchant guids to disabled
            otherItemMerchants.forEach((y) => {
                y.isDisabled = true;
                y.clearPayableItem();
            });
            // set all equivalent merchant statements within item type to not disabled
            sameItemMerchants.forEach((y) => y.isDisabled = false);
        }

        if (!clickedItem.payToItem) {
            // make sure no other checkboxes in rows of the same merchant are checked, then re-enable "other" rows
            if (!sameItemMerchants.some((y) => y.payableItem.payToItem)) {
                otherItemMerchants.forEach((y) => {
                    y.isDisabled = false;
                });
            }
        }

        // re-calculate the total
        this.sumTotal();
    }

    /**
     * Determines which rows to disable/enable based on the itemType and merchantCredentialGuid of the checked PayableStatement
     *
     * @param {PayableStatement} clickedStatement
     *
     * @memberOf PayableItemListComponent
     */
    payToStatementChecked(clickedStatement: PayableStatement): void {
        const otherStatementsTypes: PayableStatementComponent[] = this.payableStatementCmpList.filter(
            (x) => x.statement.itemType !== clickedStatement.itemType);
        const otherStatementMerchants: PayableStatementComponent[] = this.payableStatementCmpList.filter(
            (x) => x.statement.merchantCredentialGUID !== clickedStatement.merchantCredentialGUID);
        const sameStatementMerchants: PayableStatementComponent[] = this.payableStatementCmpList.filter(
            (x) => x.statement.merchantCredentialGUID === clickedStatement.merchantCredentialGUID
                && x.statement.itemType === clickedStatement.itemType);
        if (clickedStatement.payToItem) {
            // set "other" type statements to disabled
            otherStatementsTypes.forEach((y) => {
                y.isDisabled = true;
                y.enableDisableBucketRows(true);
            });
            // clear "other" type payment amounts and buckets
            otherStatementsTypes.forEach((y) => {
                y.clearPayableItemsAndBuckets();
            });
            // set all non "other" to not disabled
            this.payableStatementCmpList.filter((x) => x.statement.itemType === clickedStatement.itemType).forEach((y) => {
                y.isDisabled = false;
            });

            // set non-equal merchant guids to disabled
            otherStatementMerchants.forEach((y) => {
                y.isDisabled = true;
                y.enableDisableBucketRows(true);
                y.clearPayableItemsAndBuckets();
            });
            // set all equivalent merchant statements within item type to not disabled
            sameStatementMerchants.forEach((y) => y.isDisabled = false);
        }
        if (!clickedStatement.payToItem) {
            // make sure no other checkboxes in rows of the same type are checked, then re-enable "other" rows
            if (!this.payableStatementCmpList.filter(
                (x) => x.statement.itemType === clickedStatement.itemType).some((y) => y.statement.payToItem)) {
                otherStatementsTypes.forEach((y) => {
                    y.isDisabled = false;
                    y.enableDisableBucketRows(false);
                });
            }
            // make sure no other checkboxes in rows of the same merchant are checked, then re-enable "other" rows
            if (!sameStatementMerchants.some((y) => y.statement.payToItem)) {
                otherStatementMerchants.forEach((y) => {
                    y.isDisabled = false;
                    y.enableDisableBucketRows(false);
                });
            }
        }
        // re-calculate the total
        this.sumTotal();
    }

    /**
     * Clears document and bucket amounts and checkboxes. Also resets all validation messages.
     *
     * @memberof PayableItemListComponent
     */
    clearSelections(): void {
        this.calculatedSum = 0;
        this.paymentPlanSum = 0;
        this.payableStatementCmpList.forEach(statement => {
            statement.paymentBucketCmpList.forEach(bucket => {
                bucket.isDisabled = bucket.hardDisabled;
                bucket.bucket.bucketPaymentAmount = null;
                bucket.bucket.payToBucket = false;
                bucket.overpayDisableUI = false;
            });
            statement.statement.paymentAmount = null;
            statement.statement.payToItem = false;
            statement.isDisabled = false;
            statement.bucketsAreDirty = false;
            statement.showBucketAdjustmentDisabledDisclaimer = false;
            statement.showPromptPayShortPayDisclaimer = false;
        });

        this.payableItemCmpList.forEach(item => {
            item.payableItem.paymentAmount = null;
            item.payableItem.payToItem = false;
            item.isDisabled = false;
        });

        this.payableEntryCmpList.forEach(payableEntryCmp => {
            payableEntryCmp.payableEntry.paymentAmount = null;
            payableEntryCmp.payableEntry.payToItem = false;
            payableEntryCmp.payableEntry.isDisabled = false;
        });
    }

    getPayableEntryBalanceDisclaimer(payableEntry: PayableEntry): string {
        if ((payableEntry.itemType === PayableEntryType.paymentplanbalance ||
            payableEntry.itemType === PayableEntryType.accountBalanceGroup) && this.showPaymentPlanBalances) {
            if (this.domainInfo.enableQuickPayBalanceDetails && this.isOneTimePayment) {
                return this.quickPayBalanceDetailsWarning;
            }
            return this.paymentPlanBalanceMessage;
        }
        return this.currentBalanceDisclaimer;
    }

    getBalanceDisclaimer(payableItem: PayableItem): string {
        if (payableItem.itemType === PayableItemType.paymentplanbalance && this.showPaymentPlanBalances) {
            if (this.domainInfo.enableQuickPayBalanceDetails && this.isOneTimePayment) {
                return this.quickPayBalanceDetailsWarning;
            }
            return this.paymentPlanBalanceMessage;
        }
        return this.currentBalanceDisclaimer;
    }

    getHeaderText(payableItem: PayableItem): string {
        // get the first item in the list of the same type as the passed in item
        const firstIndex = this.payableItems.findIndex(i => i.id === this.payableItems.find(p => p.itemType === payableItem.itemType).id);
        // see if the passed in item is at the same index
        const passedInItemIndex = this.payableItems.findIndex(p => p.id === payableItem.id);

        if (firstIndex === passedInItemIndex) {
            switch (payableItem.itemType) {
                case PayableItemType.paymentplanbalance:
                    return this.accountBalanceHeaderText;
                case PayableItemType.paymentplan:
                    return this.paymentPlanHeaderText;
                default:
                    return null;
            }
        } else {
            return null;
        }
    }

    getPayableEntryHeaderText(payableEntry: PayableEntry): string {
        // get the first item in the list of the same type as the passed in item
        const firstItemOfType = this.payableEntryList.find(p => p.itemType === payableEntry.itemType).id;
        const firstIndex = this.payableEntryList.findIndex(i => i.id === firstItemOfType);
        // see if the passed in item is at the same index
        const passedInItemIndex = this.payableEntryList.findIndex(p => p.id === payableEntry.id);

        if (firstIndex === passedInItemIndex) {
            switch (payableEntry.itemType) {
                case PayableEntryType.paymentplanbalance:
                    return this.accountBalanceHeaderText;
                case PayableEntryType.paymentplan:
                    return this.paymentPlanHeaderText;
                default:
                    return null;
            }
        } else {
            return null;
        }
    }

    displayRealtimeBalanceRow() {
        return !!this.realtimeBalance;
    }

    anyAccountPaymentPlanEligible(): boolean {
        if (this.payableItems.length > 0) {
            return this.payableItems.some(x => x.itemType !== PayableItemType.paymentplan && x.isAccountPaymentPlanEligible);
        } else if (this.payableEntryList.length > 0) {
            return this.payableEntryList.some(x => x.itemType !== PayableEntryType.paymentplan && x.isAccountPaymentPlanEligible);
        } else {
            return true;
        }
    }

    public getBalancePaymentPostDate(balance: IPaymentPlanBalance): Date {
        if (this.domainInfo.balanceSortingColumn === 'ServiceDate' && balance.serviceDate) {
            return balance.serviceDate;
        } else {
            return balance.balanceAsOf;
        }
    }

    async getPaymentPlansAndBalances() {
        if (this.componentService.userIsLoggedIn()) {
            let account;
            try {
                account = await this.consumerService.getConsumerAccount();
            } catch (e) {
                this.componentService.loggingService.log('error retrieving consumer account', LoggingLevel.error, e);
                this.paymentPlansAndBalancesError = this.paymentPlansAndBalancesErrorText;
                this.internalServerErrorEvent.emit(true);
                return;
            }
            this.paymentPlansAndBalancesError = '';
            if (account != null) {
                this.hasConsumerAccount = true;
                await Promise.all([
                    this.getBalances(account.customerAccountID),
                    this.getPaymentPlanDetails(account.customerAccountID)
                ]).catch(error => {
                    this.paymentPlansAndBalancesError = this.paymentPlansAndBalancesErrorText;
                    this.componentService.loggingService.log('error retrieving payment plan balances.', LoggingLevel.error, 500);
                    this.internalServerErrorEvent.emit(true);
                });
            }
            this.showPaymentPlanItems();
            if (this.domainInfo.groupAccountBalances) {
                this.allocatePostingPercentsToChildItems();
            }
        }
    }

    private allocatePostingPercentsToChildItems() {
        this.payableEntryPaymentPlanBalances.forEach(parentEntry => {
            if (!!parentEntry.childItems) {
                parentEntry.payableAmount =
                    parentEntry.maxPayableAmount =
                    parentEntry.childItems.reduce((sum, childItem) => sum + Math.round(100 * Math.abs(childItem.payableAmount)), 0) / 100;
                parentEntry.childItems.forEach(childItem => {
                    switch (this.domainInfo.balanceDetailsPostingMethod) {
                        case BalanceDetailsPostingMethod.FIFO:
                            childItem.percentAllocation = 100;
                            break;
                        default:
                            // If we don't have a recognized posting method we'll call it WEIGHTED:
                            childItem.percentAllocation = childItem.payableAmount / parentEntry.payableAmount * 100;
                        // TODO: EQUAL will be coming in a later story
                        // default:
                        //     childItem.percentAllocation = 100 / parentEntry.childItems.length;
                    }
                });
                const totalPercentAllocated =
                    parentEntry.childItems.reduce((sum, childItem) => sum + Math.abs(childItem.percentAllocation), 0);
                parentEntry.childItems[parentEntry.childItems.length - 1].percentAllocation -=
                    (totalPercentAllocated - 100);
            }
        });
    }

    async getBalances(customerAccountID: string) {
        const balances = await this.paymentPlanService.getBalanceDetails(customerAccountID);

        // We need this saved to the service because a Deviceless Payment will not have this information available.
        if (
            this.isAgentAssisted &&
            !this.domainInfo.enablePayableEntries &&
            !this.domainInfo.groupAccountBalances
        ) {
            this.componentService.setPayableEntryOrItemBalances(balances);
        }

        if (balances != null && balances.length > 0) {
            const payableBalances = balances.filter(this.balanceFilter.bind(this));

            if (payableBalances.length > 0) {
                this.noPayableBalancesExist = false;

                if (this.domainInfo.enablePayableEntries) {
                    if (this.domainInfo.groupAccountBalances) {
                        this.createAccountBalanceGroups(payableBalances);
                    } else {
                        this.payableEntryPaymentPlanBalances = payableBalances.map(this.payableEntryBalanceMapper.bind(this));
                    }
                } else {
                    this.paymentPlanBalances = payableBalances.map(this.balanceMapper.bind(this));
                }
            }
        } else {
            this.componentService.loggingService.log('no eligible balances found.', LoggingLevel.debug);
        }
    }

    private createAccountBalanceGroups(payableBalances: IPaymentPlanBalance[]) {
        payableBalances.forEach(balance => {
            let parentEntry = this.payableEntryPaymentPlanBalances
                .find(x => x.merchantProfileGUID === balance.merchantProfileGUID);

            if (!parentEntry) {
                parentEntry = PayableEntry
                    .initializeMerchantProfileGroup(
                        balance.merchantProfileGUID,
                        this.componentService.generateUUID());
                this.payableEntryPaymentPlanBalances.push(parentEntry);
            }

            parentEntry.childItems
                .push(PayableEntry.initializeBalance(balance, this.componentService.generateUUID(), ));
        });
    }

    async getPaymentPlanDetails(customerAccountID: string) {
        const plans = await this.paymentPlanService.getPaymentPlanDetailsForAccount(customerAccountID);
        if (plans != null && plans.length > 0) {
            this.noPaymentPlansExist = false;
            if (this.domainInfo.enablePayableEntries) {
                this.payableEntryPaymentPlans = plans.map(this.payableEntryPaymentPlanMapper.bind(this));
            } else {
                this.paymentPlans = plans.map(this.paymentPlanMapper.bind(this));
            }
        }
    }

    balanceFilter(b: IPaymentPlanBalance): boolean {
        return (b.balance || 0) > 0
            && b.balanceMethod === BalanceMethod.balancedetails
            && b.paymentPlanGUID === this.componentService.NULL_GUID
            && b.isActive;
    }

    payableEntryBalanceMapper(balance: IPaymentPlanBalance): PayableEntry {
        return PayableEntry.initializeBalance(balance, this.componentService.generateUUID());
    }

    payableEntryPaymentPlanMapper(plan: IPaymentPlanDetails): PayableEntry {
        const description = this.paymentPlanRemainingBalanceText
            .replace('!REMAINING_AMOUNT!', this.currencyPipe.transform(plan.balance, 'USD', 'symbol'));
        return PayableEntry.initializePaymentPlan(plan, description);
    }

    balanceMapper(balance: IPaymentPlanBalance): PayableItem {
        return {
            itemType: PayableItemType.paymentplanbalance,
            postDate: this.getBalancePaymentPostDate(balance),
            itemName: balance.description,
            id: this.componentService.generateUUID(),
            payableAmount: balance.balance,
            dueDate: null,
            payToItem: false,
            paymentAmount: null,
            merchantProfile: null,
            merchantProfileGUID: balance.merchantProfileGUID,
            merchantCredentialGUID: null,
            itemDescription: balance.accountNumber,
            maxPayableAmount: balance.balance,
            hasScheduledPayment: false,
            isAccountPaymentPlanEligible: balance.isAccountPaymentPlanEligible,
            displayField: balance.displayField,
            paymentLocationGUID: balance.paymentLocationGUID,
            initialPayment: balance.initialPayment,
            initialPaymentAmount: balance.initialPaymentAmount
        };
    }

    paymentPlanMapper(plan: IPaymentPlanDetails): PayableItem {
        return {
            itemType: PayableItemType.paymentplan,
            postDate: plan.firstDate,
            itemName: 'Plan ' + plan.sequentialIdentifier,
            id: plan.paymentPlanGUID,
            payableAmount: plan.balance > plan.expectedPaymentAmount ? plan.expectedPaymentAmount : plan.balance,
            dueDate: plan.expectedPaymentDate,
            payToItem: false,
            paymentAmount: null,
            merchantProfile: null,
            merchantProfileGUID: plan.merchantProfileGUID,
            merchantCredentialGUID: null,
            itemDescription: this.paymentPlanRemainingBalanceText.replace('!REMAINING_AMOUNT!', this.currencyPipe.transform(plan.balance, 'USD', 'symbol')),
            hasScheduledPayment: !!plan.consumerPaymentMethod,
            maxPayableAmount: plan.balance,
            isAccountPaymentPlanEligible: true,
            paymentLocationGUID: null,
            initialPayment: plan.initialPayment,
            initialPaymentAmount: plan.initialPaymentAmount
        };
    }

    async showPaymentPlanItems() {
        const payableEntriesExist = (this.domainInfo.enablePayableEntries &&
            (!!this.payableEntryPaymentPlans && this.payableEntryPaymentPlans.length > 0) ||
            (!!this.payableEntryPaymentPlanBalances && this.payableEntryPaymentPlanBalances.length > 0));

        const payableItemsExist = (!this.domainInfo.enablePayableEntries &&
            (this.paymentPlans && this.paymentPlans.length > 0) ||
            (this.paymentPlanBalances && this.paymentPlanBalances.length > 0));

        this.showPaymentPlanBalances = payableEntriesExist || payableItemsExist;

        if (this.showPaymentPlanBalances) {
            if (this.domainInfo.enablePayableEntries) {
                this.payableEntryList = this.payableEntryPaymentPlanBalances.concat(this.payableEntryPaymentPlans);
                this.payableEntryList.forEach(async payableEntry => {
                    payableEntry.merchantProfile =
                        await this.consumerService.getMerchantProfileByMerchantProfileGUID(payableEntry.merchantProfileGUID);

                    if (!!payableEntry.childItems) {
                        payableEntry.childItems.forEach(async childItem => {
                            childItem.merchantProfile =
                                await this.consumerService.getMerchantProfileByMerchantProfileGUID(childItem.merchantProfileGUID);
                        });
                    }

                    if (this.domainInfo.groupAccountBalances && payableEntry.itemType === PayableEntryType.accountBalanceGroup) {
                        payableEntry.itemName = payableEntry.merchantProfile.friendlyName;
                    }
                });
            } else {
                this.payableItems = this.paymentPlanBalances.concat(this.paymentPlans);
                this.payableItems.forEach(async item => {
                    item.merchantProfile = await this.consumerService.getMerchantProfileByMerchantProfileGUID(item.merchantProfileGUID);
                });
            }
        }
    }

    private async getStatementsFromConsumerService(): Promise<void> {
        if (this.componentService.userIsLoggedIn()) {
            // This is to double-check that we have a wallet when we need it, otherwise intermittent bad things can happen with wallet stuff
            if (this.isOneTimePayment) {
                this.walletDoneLoading();
            } else {
                await this.consumerService.getWalletPaymentMethods();
                this.walletDoneLoading();
            }

            this.statementList = await this.consumerService.getPayableStatements();
            if (this.statementList.length > 0) {
                this.statementList.forEach((statement) => {
                    // Don't call done until the last statement is checked.
                    if (this.statementList.indexOf(statement) === this.statementList.length - 1) {
                        this.statementsDoneLoading();
                    }
                });

                if (this.domainTotalBalanceType === 'Last Statement') {
                    this.lastStatement = this.statementList.shift();
                }

                this.payableStatementCmpList.forEach(payableStatementComp => {
                    payableStatementComp.setPaymentAmountMax();
                });

            } else {
                this.statementsDoneLoading();
            }
        }
    }

    public async arePayableStatementsInvalid(): Promise<boolean> {
        this.payableStatementCmpList.forEach(async x => {
             if (await x.isPayableStatementInvalid()) {
                return true;
             }
        });

        return false;
    }

    private statementsDoneLoading() {
        this.statementsLoading = false;
    }

    private walletDoneLoading() {
        this.walletLoading = false;
    }

    private itemsLoading() {
        return this.paymentPlansLoading || this.balancesLoading;
    }

    public isLoading(): boolean {
        this.applyExternalPaymentPlanAmount();
        return this.statementsLoading || this.walletLoading || this.itemsLoading();
    }

    private applyExternalPaymentPlanAmount(): void {
        // isLoading() is called many times when everything is done loading.  Every time the bucket list is expanded this is called again.
        // this.doneLoading makes sure it is the first time it is done loading and does not call again after.
        if (!this.statementsLoading
            && !this.walletLoading
            && !this.itemsLoading()
            && !this.checkedExternalPayments
            && this.payableStatementCmpList.length > 0) {
            this.checkedExternalPayments = true;
            this.payableStatementCmpList.forEach(payableStatementCmp => {
                payableStatementCmp.setExternalPaymentPlan();
            });
        }
    }

    /**
     * TODO - APX-9846: as part of the refactor, this method may need to check the parameter for null
     *  and return 'parts' rather than use it to set item.itemDescription
     * From BasePaymentBucketComponent
     */
    public computeBucketChargeDetailsDescription(item: PayableEntry): void {
        const parts: string[] = [];

        if (item.accountNumber) {
            parts.push(item.accountNumber);
        }
        if (!!item.chargeDate
            && item.chargeDate instanceof Date
            && !isNaN(item.chargeDate.getTime())) {
            parts.push(this.datePipe.transform(item.chargeDate, 'MMM d'));
        }

        item.itemDescription = parts.join(' - ');
    }
}
