// Angular
import '@angular/animations';
import '@angular/common';
import '@angular/common/http';
import '@angular/compiler';
import '@angular/core';
import '@angular/forms';
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/platform-browser/animations';
import '@angular/router';
import '@angular/router';

// RxJS
import 'angular2-text-mask';
import 'moment';
import 'ng2-ion-range-slider';
// Other vendors for example jQuery, Lodash or Bootstrap
// You can import js, ts, css, sass, ...
// Only node_modules libraries.  If included in .sln, then not here
// tslint:disable-next-line:ordered-imports
import 'bootstrap/js/tooltip.js';
// tslint:disable-next-line:ordered-imports
import 'bootstrap/js/popover.js';
import 'ng2-validation';
import 'ngx-bootstrap';
import 'ngx-webstorage';
import 'rxjs';
import 'text-mask-core';

// CSS
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'ion-rangeslider/css/ion.rangeSlider.css';
import 'ion-rangeslider/css/ion.rangeSlider.skinHTML5.css';
