import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDomainInfo } from '../../../../models/domaininfo';
import { PaymentMethod, PaymentMethodType } from '../../../../models/paymentmethod';
import { ComponentService } from '../../../../services/component/component.service';
import { ConsumerService } from '../../../../services/consumer/consumer.service';
import { BasePaymentMethod } from '../../../Base/BasePaymentMethod/basepaymentmethod';

@Component({
    selector: 'profile-status',
    template: require('./profilestatus.component.html'),
    styles: [require('./profilestatus.component.css')]
})
export class ProfileStatusComponent extends BasePaymentMethod implements OnInit, OnDestroy {

    @Input() deliveryMethod: string;
    @Input() smsNotifications: string;
    @Input() mySecureWallet: PaymentMethod;

    deliveryMethodLabel: string;
    smsNotificationsLabel: string;
    mySecureWalletLabel: string;

    // semi-colon delimited list of options:
    // "Paper", "Email", "Text", "Email & Paper", "Email & Text", "Text & Paper", and "Email, Text, & Paper"
    profileDeliveryMethodOptions: string;
    noWalletText: string;
    expirationDateFullText: string;
    domainInfo: IDomainInfo;
    mySecureWalletExpired: boolean;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private componentService: ComponentService, private consumerService: ConsumerService) {
        super(componentService);
    }

    get profileDeliveryMethodOptionSettings(): string {
        return this.componentService.profileDeliveryMethodOptions;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        this.componentService.contentService.content$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((content: any) => {
                this.deliveryMethodLabel = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageDeliveryMethodLabel').text;
                this.smsNotificationsLabel = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageSmsNotifications').text;
                this.mySecureWalletLabel = this.componentService.contentService.tryGetContentItem(content, 'home', 'profile', 'homePageMySecureWalletLabel').text;
                this.profileDeliveryMethodOptions = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileDeliveryMethodOptions').text;
                this.noWalletText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'pageText', 'profileNoWalletText').text;
                this.expirationDateFullText = this.componentService.contentService.tryGetContentItem(content, 'profile', 'wallet', 'profileWalletExpirationDateFullText').text;
            });

        // choose the correct profileDeliveryMethodOptions string to use from the list in this content item.
        if (this.deliveryMethod != null && this.profileDeliveryMethodOptions != null) {
            this.componentService.setProfileDeliveryMethodOptions(
                this.consumerService.getSelectedDeliveryMethods(
                    this.profileDeliveryMethodOptions,
                    this.deliveryMethod
                )
            );
        }

        this.componentService.domainService.domainInfo$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
            domainInfo => {
                this.domainInfo = domainInfo;
            });

        this.mySecureWalletExpired = this.isExpired(this.mySecureWallet);
    }

    private walletDescription(): string {
        if (!!this.mySecureWallet) {
            if (this.mySecureWallet.paymentMethodType === PaymentMethodType.credit) {
                return 'Card XXXX' + this.mySecureWallet.paymentMethodLast4;
            } else {
                return 'Bank Account XXXX' + this.mySecureWallet.paymentMethodLast4;
            }
        }
    }

    private showLogo(): boolean {
        // don't show logo for ACH PaymentMethodType
        return (!!this.mySecureWallet && this.mySecureWallet.paymentMethodType !== PaymentMethodType.ach);
    }

    private enableSMS(): boolean {
        if (this.domainInfo) {
            if (this.domainInfo.enableSMS) {
                return true;
            }
        }
        return false;
    }

    private enableSecureWallet(): boolean {
        if (this.domainInfo) {
            if (this.domainInfo.enableWallet) {
                return true;
            }
        }
        return false;
    }
}
